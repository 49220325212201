import React from 'react';
import PropTypes from 'prop-types';
import Form from '@components/domain/form';

const CourseConfirmationForm = ({ courseId, data, confirmationForm, setConfirmationForm }) => {
  return (
    <Form
      header={data.introduction}
      courseId={courseId}
      questions={data.questions}
      values={confirmationForm}
      onChange={setConfirmationForm}
    />
  );
};

export default CourseConfirmationForm;

CourseConfirmationForm.propTypes = {
  courseId: PropTypes.string,
  data: PropTypes.shape({
    introduction: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            type: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.string),
            disabled: PropTypes.bool,
            required: PropTypes.bool,
            max: PropTypes.number
          })
        )
      })
    )
  }),
  confirmationForm: PropTypes.object,
  setConfirmationForm: PropTypes.func
};
