import React, { useEffect, useState } from 'react';
import Layout from '@components/domain/shared/layout';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql, Link } from "gatsby";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Description from '@components/domain/course-details/description';
import EmptySpace from '@components/ui/empty-space';
import PropTypes from 'prop-types';
import Spacing from '@components/ui/spacing';
import styles from '@styles/templates/curso-candidatura.module.scss';
import Spinner from '@components/ui/spinner';
import { useAuth0 } from "@auth0/auth0-react";
import {HttpClient} from "@utilities/http-client";
import {validate} from '@components/domain/form';
import Submission, {NotAllowedError} from "@components/domain/form/submission";
import CourseConfirmationForm from "@components/domain/course-confirmation";

const CursoConfirmacao = (props) => {
  const [ user, setUser ] = useState();
  const [ confirmation, setConfirmation ] = useState({});
  const [ errors, setErrors ] = useState([]);
  const [ submissionError, setSubmissionError ] = useState();
  const [ hasConfirmation, setHasConfirmation ] = useState();
  const [ confirmationError, setConfirmationError ] = useState();
  const [ submitted, setSubmitted ] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { data } = props;
  const course = data.course.nodes[0];
  const full_course_name = course.course.name + " " + course.iteration_name;
  const breakpoints = useBreakpoint();

  const httpClient = HttpClient(getAccessTokenSilently);

  const resourcePath = `auth/confirmation/${course.course_iter_id}`;

  // load user profile
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    (async () => {
      try {
        const response = await httpClient("auth/");
        if (!response.ok) {
          console.error("An error has occured.", response.status);
          return;
        }

        const userJson = await response.json();
        setUser(userJson);

        const confirmationFormResponse = await httpClient(resourcePath);

        const confirmationFormJson = await confirmationFormResponse.json();
        if (!confirmationFormResponse.ok) {
          if (confirmationFormJson.message)
            setConfirmationError(confirmationFormJson.message)
          setHasConfirmation(false);
          return;
        }


        // init profile form with existing data
        if (confirmationFormJson) {
          setConfirmation({
            ...confirmationFormJson, email: userJson.email
          });
          setHasConfirmation(true);
        }else
          setHasConfirmation(false);

      } catch (error) {
        console.error("An error has occured.", error);
      }
    })();
  }, [isAuthenticated]);

  const onSubmit = () => {
    let errors = [];

    props.data.confirmation_form.questions.map(group => {
      group.fields.map(field => {
        errors = [ ...errors, ...validate(group, field, confirmation[field.id]) ];
      });
    });

    setErrors(errors);

    if (errors.length === 0 && !submitted) {
      (async () => {
        try {
          // eslint-disable-next-line no-unused-vars
          const filtered_profile = (({email, authorize_camera, authorize_online, authorize_gdpr, ...others}) => ({...others}))(confirmation);

          const response = await httpClient(resourcePath, {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(filtered_profile)
          });

          setSubmitted(true);

          if (!response.ok) {
            console.error("An error has occured.", response.status);
            const errorJson = await response.json();
            setSubmissionError(errorJson.message || true);
            return;
          }

          setSubmissionError(false);
        } catch (error) {
          console.error("An error has occured.", error);
        }
      })();
    }
  };

  return (
    <Layout extraFooterPadding={breakpoints.mobile ? 200 : 0}>
      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
      <div className={styles.container}>
        <EmptySpace desktop={{ margin: 30 }} mobile={{ margin: 20 }} />
        <Spacing>
          <div className={styles.metadata}>
            <section className={styles.overview}>
              <Description
                title="Confirmar Participação"
                courseName={full_course_name}
                scholarity={course.scholarity}
              />
            </section>
          </div>
        </Spacing>
        <Spacing>
          {!user && <Spinner />}
          {user && hasConfirmation === false &&
          <>
            <NotAllowedError
              error={confirmationError || "Erro ao carregar a informação pedida."}
              errorDescription="Não foi possível carregar a página de confirmação para este curso."
            />
            <div className={styles.controls}>
              <Link to={'/'}>Voltar à página anterior</Link>
            </div>
          </>
          }
          {user && hasConfirmation &&
          <>
            {!submitted &&
            <CourseConfirmationForm
                courseId={course.course_iter_id}
                data={props.data.confirmation_form || []}
                confirmationForm={confirmation}
                setConfirmationForm={setConfirmation}
            />
            }
            {submitted && <Submission
                loading={submissionError === undefined}
                error={submissionError}
                errorDescription="Ocorreu um erro ao submeter a confirmação."
                successDescription="Os dados de acesso às plataformas online serão enviados por email dentro de momentos."
                successMessage="A confirmação foi registada."
            />}
            {errors.length > 0 &&
            <div className={styles.errors}>
              <div className={styles.title}>Erros de validação:</div>
              <ul>
              {errors.map((error, i) =>
                <li key={i}>{error.message}</li>
              )}
              </ul>
            </div>
            }
            {!submitted &&
            <div className={styles.controls}>
              <a className={styles.continue} onClick={onSubmit}>Submeter</a>
            </div>
            }
          </>
          }
        </Spacing>
      </div>
      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
    </Layout>
  );
};

export default withAuthenticationRequired(CursoConfirmacao);


export const pageQuery = graphql`
query CourseConfirmationQuery($iteration_id: String) {
  course: allRestApiCourseIterations(filter: {course_iter_id: {eq: $iteration_id}}) {
    nodes {
      course {
        name
      }
      scholarity
      course_iter_id
      iteration_name
    }
  }
  confirmation_form: confirmationJson {
    questions {
      title
      description
      fields {
        id
        label
        description
        type
        required
        disabled
        options
      }
    }
    introduction
  }
}
`;

CursoConfirmacao.propTypes = {
  path: PropTypes.string,
  data: PropTypes.shape({
    course: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          course_iter_id: PropTypes.string,
          iteration_name: PropTypes.string,
          scholarity: PropTypes.string,
          course: PropTypes.shape({
            name: PropTypes.string
          }),
        })
      )
    }),
    confirmation_form: PropTypes.shape({
      introduction: PropTypes.string,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          fields: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              label: PropTypes.string,
              type: PropTypes.string,
              options: PropTypes.arrayOf(PropTypes.string),
              disabled: PropTypes.bool,
              required: PropTypes.bool,
              max: PropTypes.number
            })
          )
        })
      )
    })
  })
};